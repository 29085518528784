import { compact } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('order', [
      'getAdjustments',
      'getLineItems',
      'getGiftPromotions'
    ]),
    checkoutLineItems () {
      let standardLineItems = (this.getLineItems || []).map(lineItem => ({ ...lineItem, lineItemType: 'product' }))
      let virtualLineItems = compact([this.whiteGloveLineItem, ...this.giftPromotionLineItems])

      return [...standardLineItems, ...virtualLineItems]
    },
    cartPreviewLineItems () {
      return this.checkoutLineItems.filter(lineItem => lineItem.lineItemType !== 'serviceLevel')
    },
    whiteGloveLineItem () {
      if (!this.whiteGloveAdjustment) return

      return {
        id: this.whiteGloveAdjustment.id,
        lineItemType: 'serviceLevel',
        product_name: 'White Glove Delivery',
        total_price: 165,
        variant: {
          dimensions_properties: [{}],
          image_alternative_text: 'Beige house icon',
          image: require('@/assets/images/house.svg'),
        }
      }
    },
    whiteGloveAdjustment() {
      return this.getAdjustments?.find(adjustment => adjustment.label === 'White Glove Shipping')
    },
    giftPromotionLineItems () {
      return this.getGiftPromotions.map(this.getGiftPromotionLineItem)
    }
  },
  methods: {
    getLineItemLinkComponent(lineItem) {
      return this.isVirtualLineItem(lineItem) ? 'div' : 'NuxtLink'
    },
    getLineItemLinkObject(lineItem) {
      if (this.isVirtualLineItem(lineItem)) return null

      return { name: 'products-slug', params: { slug: lineItem.variant.slug } }
    },
    isVirtualLineItem ({ lineItemType }) {
      return ['serviceLevel', 'giftPromotion'].includes(lineItemType)
    },
    isGiftPromotion ({ lineItemType }) {
      return ['giftPromotion'].includes(lineItemType)
    },
    getGiftPromotionLineItem (giftPromotion) {
      const namePrefix = this.getGiftPromotionLineItemNamePrefix(giftPromotion)

      return {
        id: giftPromotion.id,
        lineItemType: 'giftPromotion',
        product_name: `${namePrefix} in S&N Rewards Cash`,
        total_price: 0,
        delivered_at: giftPromotion.starts_at,
        expires_at: giftPromotion.expires_at,
        dimensions: [],
        variant: {
          dimensions_properties: [{}],
          image_alternative_text: 'Coin icon',
          image: require('@/assets/images/coin.svg'),
        }
      }
    },
    getGiftPromotionLineItemNamePrefix ({ discount_type, amount }) {
      if (discount_type === 'percent') return `${amount}%`

      return `$${(+amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    }
  }
}